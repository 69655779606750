import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, FormControl, InputAdornment, Link, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, useEffect } from 'react'
import { useController, useForm } from 'react-hook-form'

import { AuthenticationButton } from '../../authentication/components/AuthenticationButton/AuthenticationButton'
import { track } from '../../main/coderpad_analytics'
import { secondaryLinkTrack } from '../LoginForm/LoginForm'

interface IPasswordFormProps {
  email: string
  password: string
  setPassword: (arg0: string) => void
  isLoading: boolean
  setShowPasswordForm: (arg0: boolean) => void
  handleLogin: () => void
  onChangePassword: () => void
  onErrorChange: (error: boolean) => void
  resetError: () => void
}

export const PasswordForm: React.FC<React.PropsWithChildren<IPasswordFormProps>> = ({
  email,
  password,
  setPassword,
  isLoading,
  setShowPasswordForm,
  handleLogin,
  onChangePassword,
  onErrorChange,
  resetError,
}) => {
  const { handleSubmit, control } = useForm()

  const {
    field: { ref, onChange },
    meta: { invalid },
  } = useController({
    name: 'password',
    control,
    defaultValue: password,
    rules: {
      required: true,
    },
  })

  const handleChangeEmail = () => {
    resetError()
    setPassword('')
    onErrorChange(false)
    setShowPasswordForm(false)
    secondaryLinkTrack(2, 'change email')
  }

  const handleChangePassword = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(e)
    setPassword(e.target.value)
    onChangePassword()
  }

  useEffect(() => {
    track('login step-2 password entry viewed')
  }, [])

  useEffect(() => {
    onErrorChange(invalid != null)
  }, [invalid, onErrorChange])

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      flex={1}
      role="passwordForm"
      onSubmit={handleSubmit(handleLogin)}
    >
      <FormControl fullWidth>
        <Box display="flex" sx={{ margin: '23px 0' }}>
          <ArrowBackIcon sx={{ cursor: 'pointer', marginRight: 1 }} onClick={handleChangeEmail} />
          <Typography component="span">{email}</Typography>
        </Box>
        <TextField
          id="password"
          placeholder="Password"
          type="password"
          value={password}
          variant="outlined"
          size="small"
          onChange={handleChangePassword}
          inputRef={ref}
          error={invalid}
          helperText={invalid && 'Password is required'}
          InputProps={{
            autoFocus: true,
            endAdornment: (
              <InputAdornment position="end">
                <Link
                  href="/password/new"
                  underline="none"
                  onClick={() => secondaryLinkTrack(2, 'forgot password')}
                >
                  Forgot Password
                </Link>
              </InputAdornment>
            ),
          }}
        />
        <AuthenticationButton
          type="submit"
          disabled={isLoading}
          isLoading={isLoading}
          sx={{ marginTop: '24px' }}
        >
          Log in
        </AuthenticationButton>
      </FormControl>
    </Box>
  )
}
